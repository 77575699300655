var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_ap_payment_report") } },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.formRules,
            "label-align": "left",
            "label-col": { span: 8 },
            "wrapper-col": { span: 12 }
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_ap_payment_number"),
                        prop: "aPPaymentNo"
                      }
                    },
                    [
                      _c("SelectApPayment", {
                        on: { "update:meta": _vm.onChangeApPayment },
                        model: {
                          value: _vm.formModel.aPPaymentNo,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "aPPaymentNo", $$v)
                          },
                          expression: "formModel.aPPaymentNo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t("lbl_branch"), prop: "branchId" }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "allow-clear": "",
                            "show-search": "",
                            "filter-option": false,
                            loading: _vm.loading.branch
                          },
                          on: {
                            change: _vm.onChangeBranch,
                            search: _vm.onSearchBranch
                          },
                          model: {
                            value: _vm.formModel.branchId,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "branchId", $$v)
                            },
                            expression: "formModel.branchId"
                          }
                        },
                        _vm._l(_vm.optBranch, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.key, attrs: { value: item.value } },
                            [
                              _c(
                                "a-tooltip",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function() {
                                          return [_vm._v(_vm._s(item.label))]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_supplier"),
                        prop: "supplierId"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "allow-clear": "",
                            "show-search": "",
                            "filter-option": false,
                            loading: _vm.loading.supplier,
                            "dropdown-match-select-width": false
                          },
                          on: {
                            change: _vm.onChangeSupplier,
                            search: _vm.onSearchSupplier
                          },
                          model: {
                            value: _vm.formModel.supplierId,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "supplierId", $$v)
                            },
                            expression: "formModel.supplierId"
                          }
                        },
                        _vm._l(_vm.optSupplier, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.key, attrs: { value: item.value } },
                            [
                              _c(
                                "a-tooltip",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function() {
                                          return [_vm._v(_vm._s(item.label))]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_supplier_type"),
                        prop: "supplierType"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "allow-clear": "",
                            "show-search": "",
                            "option-filter-prop": "children",
                            "filter-option": _vm.useLocalFilter,
                            loading: _vm.loading.supplierType
                          },
                          model: {
                            value: _vm.formModel.supplierType,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "supplierType", $$v)
                            },
                            expression: "formModel.supplierType"
                          }
                        },
                        _vm._l(_vm.optSupplierType, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.key, attrs: { value: item.value } },
                            [
                              _c("a-tooltip", [
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v(_vm._s(item.label))]
                                ),
                                _vm._v(" " + _vm._s(item.label) + " ")
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_invoice_ap_date"),
                        prop: "invoiceApDate"
                      }
                    },
                    [
                      _c("a-range-picker", {
                        attrs: { format: _vm.DEFAULT_DATE_FORMAT },
                        model: {
                          value: _vm.formModel.invoiceApDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "invoiceApDate", $$v)
                          },
                          expression: "formModel.invoiceApDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_invoice_ap_number"),
                        prop: "invoiceApNo"
                      }
                    },
                    [
                      _c("SelectInvoiceAp", {
                        on: { "update:meta": _vm.onChangeInvoiceAp },
                        model: {
                          value: _vm.formModel.invoiceApNo,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "invoiceApNo", $$v)
                          },
                          expression: "formModel.invoiceApNo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_invoice_supplier_number"),
                        "label-align": "right",
                        prop: "invoiceSuppNo"
                      }
                    },
                    [
                      _c("SelectInvoiceSuppNo", {
                        on: { "update:meta": _vm.onChangeInvoiceSupp },
                        model: {
                          value: _vm.formModel.invoiceSuppNo,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "invoiceSuppNo", $$v)
                          },
                          expression: "formModel.invoiceSuppNo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_invoice_type"),
                        "label-align": "right",
                        prop: "invoiceType"
                      }
                    },
                    [
                      _c("SelectInvoiceApType", {
                        model: {
                          value: _vm.formModel.invoiceType,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "invoiceType", $$v)
                          },
                          expression: "formModel.invoiceType"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_payment_type"),
                        "label-align": "right",
                        prop: "paymentType"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { "allow-clear": "" },
                          model: {
                            value: _vm.formModel.paymentType,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "paymentType", $$v)
                            },
                            expression: "formModel.paymentType"
                          }
                        },
                        _vm._l(_vm.optPaymentType, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.key, attrs: { value: item.value } },
                            [
                              _c(
                                "a-tooltip",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function() {
                                          return [
                                            _vm._v(_vm._s(_vm.$t(item.label)))
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [_vm._v(" " + _vm._s(_vm.$t(item.label)) + " ")]
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_payment_date_from"),
                        "label-align": "right",
                        prop: "paymentFrom"
                      }
                    },
                    [
                      _c("a-date-picker", {
                        attrs: {
                          "allow-clear": "",
                          format: _vm.DEFAULT_DATE_FORMAT
                        },
                        model: {
                          value: _vm.formModel.paymentFrom,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "paymentFrom", $$v)
                          },
                          expression: "formModel.paymentFrom"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_payment_date_to"),
                        "label-align": "right",
                        prop: "paymentTo"
                      }
                    },
                    [
                      _c("a-date-picker", {
                        attrs: {
                          "allow-clear": "",
                          format: _vm.DEFAULT_DATE_FORMAT
                        },
                        model: {
                          value: _vm.formModel.paymentTo,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "paymentTo", $$v)
                          },
                          expression: "formModel.paymentTo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.resetForm } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            "html-type": "submit",
                            type: "primary",
                            loading: _vm.loading.find
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          "data-source": _vm.dataSource,
          columns: _vm.columns,
          pagination: {
            showTotal: function(total) {
              return _vm.$t("lbl_total_items", { total: total })
            },
            showSizeChanger: true,
            pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
            current: _vm.pagination.page,
            defaultPageSize: _vm.pagination.limit
          },
          loading: _vm.loading.find,
          scroll: { x: "calc(600px + 100%)", y: 900 },
          size: "small"
        },
        on: { change: _vm.onChangeTable },
        scopedSlots: _vm._u([
          {
            key: "currency",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm._f("currency")(text)))])
            }
          },
          {
            key: "number",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm._f("qty")(text)))])
            }
          },
          {
            key: "date",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm._f("date")(text)))])
            }
          },
          {
            key: "nullable",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
            }
          }
        ])
      }),
      _c(
        "div",
        { staticClass: "text-right mt-2" },
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading.download },
              on: { click: _vm.handleDownload }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }